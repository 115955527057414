// 3rd party code
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// firebase init
const firebaseConfig = {
    apiKey: "AIzaSyCxgm0_xblIbKKeWb0RpE60Mhws614ZUIg",
    authDomain: "todo-list-c6f27.firebaseapp.com",
    projectId: "todo-list-c6f27",
    storageBucket: "todo-list-c6f27.appspot.com",
    messagingSenderId: "614160792597",
    appId: "1:614160792597:web:d9d74835c0e8a01cb2a21c"
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();

// collection references
const categoryCollection = db.collection('categories');
const listCollection = db.collection('lists');
const taskCollection = db.collection('tasks');

// export utils/refs
export {
    db,
    categoryCollection,
    listCollection,
    taskCollection,
};

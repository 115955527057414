<template>
    <b-container>
        <div class="text-center py-5 px-1">
            <p class="font-brand font-weight-bold text-brand">trioGo</p>
            <p class="font-list-title font-weight-bold text-gray-dark">
                {{ list.name }}
            </p>
        </div>
        <b-card no-body style="overflow: hidden; border-radius: 5px">
            <b-card border-variant="none" no-body style="border-radius: 0; border: none; padding: 60px">
                <overall-progress :value="overallProgress"/>
            </b-card>
            <b-card no-body style="border-radius: 0; border-left: none; border-right: none; border-bottom: none; padding: 60px">
                <p class="font-subline font-weight-bold text-gray-light">Deine Challenges</p>
                <div v-for="category in categories" v-if="getTasksByCategoryId(category.id).length > 0">
                    <category :name="category.name" :current="getCompletedByCategoryId(category.id).length" :max="getTasksByCategoryId(category.id).length"></category>
                    <task v-for="task in getTasksByCategoryId(category.id)" :key="`task-${task.id}`"
                          @click.native.prevent="handleClickCheckbox(task.id, task.completed)"
                          :task="task">
                    </task>
                </div>
            </b-card>
        </b-card>
        <div class="text-center font-brand font-weight-bold text-gray-light py-5 px-1 mb-5">
            Creating Virtual Soul.
        </div>
    </b-container>
</template>
<script>
import {mapActions, mapState} from 'vuex';
import OverallProgress from '@/components/OverallProgress';
import Task from '@/components/Task';
import Category from '@/components/Category';

export default {
    async beforeMount() {
        await this.fetchData();
    },
    components: {
        OverallProgress,
        Category,
        Task,
    },
    methods: {
        ...mapActions(['fetchList', 'fetchTasks', 'fetchCategories', 'toggleTaskCompleted']),
        getCompletedByCategoryId(categoryId) {
            return this.tasks.filter((task) => {
                return task.category_id === categoryId && task.completed === true;
            });
        },
        getTasksByCategoryId(categoryId) {
            return this.tasks.filter((task) => {
                return task.category_id === categoryId;
            });
        },
        async handleClickCheckbox(taskId, completed) {
            await this.toggleTaskCompleted({id: taskId, completed: completed});
        },
        async fetchData() {
            await this.fetchList(this.listId);
            await this.fetchTasks(this.listId);
            await this.fetchCategories(this.listId);
        },
    },
    computed: {
        ...mapState(['list', 'tasks', 'categories']),
        overallProgress() {
            const tasksCount = this.tasks.length;
            const completedTasksCount = this.tasks.filter(task => task.completed).length;
            return Math.round(completedTasksCount / tasksCount * 100);
        },
        listId() {
            return this.$route?.params?.id;
        },
    },
};
</script>

<template>
    <div>
        <b-button v-b-modal.modal-prevent-closing>Neue Liste erstellen</b-button>

        <b-modal id="modal-prevent-closing"
                 ref="modal"
                 title="Neue Liste erstellen"
                 @show="resetModal"
                 @hidden="resetModal"
                 @ok="handleOk">

            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Listen-Name"
                              label-for="list-input"
                              invalid-feedback="Bitte einen Namen für die Liste festlegen."
                              :state="listState">
                    <b-form-input id="list-input"
                                  v-model="list"
                                  :state="listState"
                                  required></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'Home',
    data() {
        return {
            list: '',
            listState: null,
        };
    },
    methods: {
        ...mapActions(['addList']),
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.listState = valid;
            return valid;
        },
        resetModal() {
            this.list = '';
            this.listState = null;
        },
        handleOk(e) {
            // Prevent modal from closing
            e.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }

            const addListHandler = await this.addList({
                name: this.list,
            });
            await this.$router.push({name: 'edit-todo', params: {id: addListHandler.id}});

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing');
            });
        },
    },
};
</script>

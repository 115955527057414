<template>
    <div id="app">
        <div class="d-flex justify-content-end">
            <img src="@/assets/triologo.png" alt="trio-group" class="logo">
        </div>
        <router-view />
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
body {
    background: #f0f6f7;
}

.font-brand {
    font-size: 20px;
    line-height: normal;
}

.font-list-title {
    font-size: 40px;
    line-height: normal;
}

.font-subline {
    font-size: 16px;
    line-height: normal;
}

.text-brand {
    color: #bd003c;
}

.text-gray-dark {
    color: #16181a;
}

.text-gray-light {
    color: #939899;
}

.progress {
    height: 7px;
    border-radius: 5px;
}

.progress-bar.bg-danger {
    background-color: #bd003c !important;
}

.logo {
    margin-top: 60px;
    margin-right: 60px;
}

</style>

import Vue from 'vue';
import Vuex from 'vuex';
// Firebase
import * as fb from '../firebase';
// Initialize vuex plugin
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        categories: [],
        list: {},
        tasks: [],
    },
    mutations: {
        SET_CATEGORIES(state, val) {
            state.categories = val;
        },
        SET_LIST(state, val) {
            state.list = val;
        },
        SET_TASKS(state, val) {
            state.tasks = val;
        },
    },
    actions: {
        async toggleTaskCompleted({commit}, task) {
            await fb.taskCollection.doc(task.id).update({completed: !task.completed});
        },
        async fetchCategories({commit}, listId) {
            await fb.categoryCollection.where('list_id', '==', listId).onSnapshot(snapshot => {
                const categories = [];
                snapshot.forEach(doc => {
                    categories.push({id: doc.id, ...doc.data()});
                });
                commit('SET_CATEGORIES', categories);
            });
        },
        async fetchList({commit}, listId) {
            await fb.listCollection.onSnapshot(snapshot => {
                snapshot.forEach(doc => {
                    if (doc.id === listId) {
                        commit('SET_LIST', doc.data());
                    }
                });
            });
        },
        async fetchTasks({commit}, listId) {
            await fb.taskCollection.where('list_id', '==', listId).onSnapshot(snapshot => {
                const tasks = [];
                snapshot.forEach(doc => {
                    tasks.push({id: doc.id, ...doc.data()});
                });
                commit('SET_TASKS', tasks);
            });
        },
        async addList({commit}, list) {
            return await fb.listCollection.add(list);
        },
    },
    modules: {},
});

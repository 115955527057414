<template>
    <div>
        <p class="font-subline font-weight-bold text-gray-light mb-4">Dein Fortschritt</p>
        <b-progress :max="100">
            <b-progress-bar :value="value" variant="danger"></b-progress-bar>
        </b-progress>
        <div class="d-flex justify-content-between mt-3 rank__wrapper">
            <template v-for="(rank, index) in ranks">
                <div class="rank" :class="rankClasses(index)">
                    <div class="rank__frame">
                        <img class="rank__img" :src="rank.img" :alt="rank.name">
                    </div>
                    <div class="rank__name">
                        {{ rank.name }}
                    </div>
                </div>
                <span v-if="index < ranks.length - 1" class="divider"></span>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {},
    },
    data() {
        return {
            ranks: [
                {
                    name: 'Newbie',
                    img: require('@/assets/rank-newbie.png'),
                },
                {
                    name: 'Beginner',
                    img: require('@/assets/rank-beginner.png'),
                },
                {
                    name: 'Challenger',
                    img: require('@/assets/rank-challanger.png'),
                },
                {
                    name: 'Pro',
                    img: require('@/assets/rank-pro.png'),
                },
                {
                    name: 'Wizard',
                    img: require('@/assets/rank-wizard.png'),
                },
            ],
        };
    },
    methods: {
        rankClasses(index) {
            const ranksCount = this.ranks.length - 1;
            let current = 0;
            for (let rankCount = 0; rankCount <= ranksCount; rankCount++) {
                if (this.value >= rankCount / ranksCount * 100) {
                    current = rankCount;
                }
            }
            return {
                ['rank--active']: this.value >= index / ranksCount * 100,
                ['rank--current']: index === current,
            }
        }
    }
};
</script>
<style lang="scss">
.rank {
    opacity: 0.5;
}

.rank--active {
    opacity: 1;
}

.rank--current {
    .rank__frame {
        border-color: #bd003c;
    }
}

.rank__name {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
}

.rank__frame {
    display: flex;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 100%;
}

.rank__img {
    width: 50%;
    height: auto;
    margin: auto;
}

.rank__wrapper {
    margin-left: -30px;
    margin-right: -30px;
}

.divider {
    width: 2px;
    height: 15px;
    background: rgba(0, 0, 0, .1);
    margin-left: auto;
    margin-right: auto;
}
</style>

<template>
    <div class="task">
        <b-form-checkbox class=""
                         :id="`checkbox-${task.id}`"
                         :name="`checkbox-${task.id}`"
                         :checked="task.completed">
            <div class="task__fake-box">
                <img class="task__fake-box-image" :src="require('@/assets/check.png')"></img>
            </div>
            <p class="mb-0 task__name">
                {{ task.name }}
            </p>
        </b-form-checkbox>
    </div>
</template>
<script>
export default {
    props: {
        task: {},
    },
};
</script>
<style lang="scss">
.task {
    margin-bottom: 20px;
    cursor: pointer;
    min-height: 42px;
    display: flex;
    align-items: center;
    width: 100%;

    .custom-control {
        padding-left: 50px;

        .custom-control-label {
            position: static;
            &:before, &:after {
                display: none;
            }
        }

        .task__fake-box {
            display: flex;
            width: 30px;
            height: 30px;
            border: 1px solid rgba(0,0,0,.1);
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .task__fake-box-image {
            width: 50%;
            height: auto;
            margin: auto;
            display: none;
        }
    }
}
.task__name {
    font-size: 16px;
    color: #606566;
    cursor: pointer;
}
.custom-control-input:checked + .custom-control-label {
    .task__name {
        color: #939899;
    }
    .task__fake-box-image {
        display: block;
        cursor: pointer;
    }
}
</style>

<template>
    <div class="d-flex justify-content-between category">
        <p class="font-brand text-gray-dark font-weight-bold m-0">
            {{ name }}
        </p>
        <p class="font-brand text-brand font-weight-bold m-0">
            {{ current }}/{{ max }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        name: {},
        current: {},
        max: {},
    },
};
</script>
<style lang="scss">
.category {
    margin-top: 20px;
    margin-bottom: 40px;
}
</style>
